.card {
    width: 30%;
    display: flex;
    flex-direction: column;
    border: 1px solid red;
    align-content: center;
    margin: 0 auto;
}

.card-header {
    height: 30%;
    background: #5d6770;
    color: white;
    text-align: center;
}

.card-header p {
    font-size: 20px;
}


label.lblRowCo {
    display: block;
    width: 133px;
    /* margin: 0; */
    font-size: .875rem;
    line-height: 1.8;
    color: #0a0a0a;
}

table.companyTbl {
    width: 100% !important;
}

select.pageRowsCo {
    font-size: small !important;
    width: 10% !important;
    height: 2.4rem !important;
    margin: 0 !important;
}

.paginationCo {
    display: flex;
    margin-left: 120px !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: black;
}
  

.row.filterSite {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}

table.deliveriesSiteTbl {
    width: 100%;
}