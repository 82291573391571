/* th.DateColWidth, td.DateColWidth{
    display: flex;
    flex: 1;
  }

  th.siteNoColWidth, td.siteNoColWidth{
    display: flex;
    flex: 1;
  }

  th.ProgessColWidth, td.ProgessColWidth{
    display: flex;
    flex: 6;
    width: 60px;
  } */

  tr.clickable-row {
    cursor: pointer;
}

.dropdownSearchCont {
  position: relative;
  width: 200px;
}


.searchDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  width: 103%;
}


.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

input.siteDropdown {
  width: 195px !important;
}