
*{
    margin:0 !important;
    padding: 0px;
    text-decoration: none;
}

/* .static {
    position: fixed;
  } */

.container{   
    padding: 0;  
    /* height: 98vh;    */
    display: flex;
    line-height: normal;
    
}

main{
    width: 100%;
    min-height: 100vh;
    flex: 1;
    display: flex !important;
    padding-bottom: 80px;
}



.navBar{
 padding-left: 0;
 padding-top: 0;
 display: flex;
 position: relative;
z-index: 2;
 
}

.pages{
    flex: 1;
    display: flex;
    margin-left: 165px !important;
    padding-left: 30px;
    margin-top: 100px !important;
    padding-top: 30px;
    
}

.sidebar {
    background: #01003A;
    color: white;
    height: 99.9vh;
    width: 180px;   
    /* transition: all 0.5s; */
    position: fixed;
    padding-top: 70px;

    
}

.top_section{
    display: flex;
    align-items: center;
    padding: 20px 15px;    
    background-color: #E7191D;
}

.bars{
    font-size: 25px;
    display: flex;
    margin-left: 13px !important;
    padding-bottom: 15px;
    padding-top: 10px;
}

.link{
    display: flex;
    color: rgb(255, 255, 255);
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s ;
}

.link:hover{
    background: rgb(0 0 0 / 7%);
    color: rgb(255, 255, 255);
    transition: all 0.5s;
}

 /* .active{
    background: rgb(201, 201, 201);
    color: rgb(0, 0, 0);
}  */

.icon, .link_text{
    font-size: 14px;
}

.icon{
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    }


.bottom {
   padding-top: 50px;
}

.title-bar{
    width: 1350px;
   
}
.icon-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-left: inherit; */

 }

 .link_text {
    padding-left: 10px;
}

/*-------New Orders-----*/
a.link.orders-link.active {
    background: linear-gradient(to right, #5C9A02, #cee0b3);
    color: black !important;  
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.orders-link.active .icon {
    color: black !important;  
}


.main.orders {
    /* background-color: #cee0b3; */
    background: url('../../media/dispatchBg.png');
    
}



 /*-------DISPATCH------*/
 
a.link.dispatched-link.active {
    background: linear-gradient(to right, #5C9A02, #cee0b3);
    color: black !important;  
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.dispatched-link.active .icon {
    color: black !important;  
}


a.link.dispatched-link {
    padding-left: 15px;
}

.main.dispatched {
    /* background-color: #cee0b3; */
    background: url('../../media/dispatchBg.png');
    
}

/*------DELIVERIES------*/
  
a.link.deliveries-link.active{
    background: linear-gradient(to right, #0065BF, #ADD6F7);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.deliveries-link.active .icon{
    color: black !important;
}


.main.deliveries {
    /* background-color: #b2d0eb; */
    background: url('../../media/receivingBg.png');
}

/*------SUPPLIERS------*/

a.link.suppliers-link.active{
    background: linear-gradient(to right, #F6B602, #FFE075);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.suppliers-link.active .icon{
    color: black !important;
}

/* a.link.suppliers-link {
    padding-left: 22px;
} */

.main.suppliers {
    /* background-color: #fce9b3; */
    background: url('../../media/suppliersBg.png');
}
  
/*------RETRURED------*/

a.link.returned-link.active{
    background: linear-gradient(to right, #AE00B9, #EBABE5);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.returned-link.active .icon{
    color: black !important;
}

/* a.link.returned-link {
    padding-left: 30px;
} */

.main.returned {
    /* background-color: #e6b2ea; */
    background: url('../../media/returnsBg.png');
}

/*------CLAIMED------*/

a.link.claims-link.active{
    background: linear-gradient(to right, #EE6501, #FFA861);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.claims-link.active .icon{
    color: black !important;
    
}

/* a.link.claims-link {
    padding-left: 33px;
} */

.main.claims {
    /* background-color: #ffbd8d;claimsBg */
    background: url('../../media/claimsBg.png');
}

/*------DELIVERIES------*/
  
a.link.pallets-link.active{
    background: linear-gradient(to right, #0065BF, #ADD6F7);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.pallets-link.active .icon{
    color: black !important;
}


.main.pallets {
    /* background-color: #b2d0eb; */
    background: url('../../media/receivingBg.png');
}
/*------REPORTS------*/

/* a.link.reports-link.active{
    background-color: red;
    color: black !important;
} 
    
.main.reports {
    background-color: red;
} */


/*-------SUPER ADMIN----*/

a.link.superAdmin-link.active{
    background: #AC0713;
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.superAdmin-link.active .icon{
    color: black !important;
    
}
.main.superAdmin{
    /* background-color: #e57878; */
    background: url('../../media/superAdminBg.png');
}

/*--------ADMIN-------*/
a.link.admin-link.active{
    background: linear-gradient(to right, firebrick, #F77C82);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.admin-link.active .icon{
    color: black !important;
    
}

.main.admin{
    background: url('../../media/adminBg.png');
}

/*-------MANAGER----*/
a.link.manager-link.active{
    background: linear-gradient(to right, firebrick, #F77C82);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.manager-link.active .icon{
    color: black !important;
    
}

.main.manager{
    background: url('../../media/adminBg.png');
}

/*-------SUPERVISOR----*/
a.link.supervisor-link.active{
    background: linear-gradient(to right, firebrick, #F77C82);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.supervisor-link.active .icon{
    color: black !important;
    
}

.main.supervisor{
    background: url('../../media/adminBg.png');
}

/*-------ORDERS PROGRESS----*/
a.link.ordersProgress-link.active{
    background: linear-gradient(to right, firebrick, #F77C82);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.ordersProgress-link.active .icon{
    color: black !important;
    
}

.main.ordersProgress{
    background: url('../../media/adminBg.png');
}

/*-------STORE AUDIT----*/
a.link.storeAudit-link.active{
    background: linear-gradient(to right, #5C9A02, #cee0b3);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.storeAudit-link.active .icon{
    color: black !important;
    
}

a.link.storeAudit-link {
    margin-top: 30px !important;
}

.main.storeAudit{
    background: url('../../media/dispatchBg.png');
}

main.main.feedback {
    background-color: beige;
}

/*-------STORE AUDIT----*/
a.link.auditMaintenance-link.active{
    background: linear-gradient(to right, #5C9A02, #cee0b3);
    color: black !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

a.link.auditMaintenance-link.active .icon{
    color: black !important;
    
}

.main.auditMaintenance{
    background: url('../../media/dispatchBg.png');
}

.disabled {
    opacity: 0.5; /* Or any other styling to make it appear grayed out */
    pointer-events: none; /* Disable pointer events to prevent clicks */
  }

