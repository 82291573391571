h4.regTitle {
    text-align: center;
}

p.errorMsg {
    padding-top: 25px;
    color: red;
    text-align: center;
}

P.message{
    padding-top: 25px;
    color: green;
    text-align: center;
}