.mainBody{
    min-height: 500px;
}

.off-canvas-content {
     background-color: #01003A !important; 
    /* margin-right: -150px !important;
    margin-top: 50px !important; */
    box-shadow: none !important;
}
.off-canvas-wrapper-inner {
    background-color: #01003A; 
    height: 100vh;
}

body{
      background-color: #B2D0EB !important; 
    line-height: 1 !important;
}

tbody, tfoot, thead {
    border: 1px solid #0a0a0a !important;
}

