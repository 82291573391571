.tab-container {
    display: flex;
    padding: 10px;
  }
  
  .tab {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
  }
  
  .tab.active {
    border: 1px solid purple;
    background-color: #cfcfcf9c;
  }

  .tab:hover {
    background-color: #cfcfcf9c;
    color: black;
}