h4.regTitle {
    text-align: center;
}

p.errorMsg {
    padding-top: 25px;
    color: red;
    text-align: center;
}

/* .createCompany-form {
    margin-top: -75px !important;
} */