.card {
    width: 30%;
    display: flex;
    flex-direction: column;
    border: 1px solid red;
    align-content: center;
    margin: 0 auto;
}

.card-header {
    height: 30%;
    background: #5d6770;
    color: white;
    text-align: center;
}

.card-header p {
    font-size: 20px;
}

.column1Company{
  width: 300px;
}

.column1Company h5{
    width: 300px;
  }


label.lblRowCo {
    display: block;
    width: 133px;
    /* margin: 0; */
    font-size: .875rem;
    line-height: 1.8;
    color: #0a0a0a;
}

table.companyTbl {
    width: 100% !important;
}

select.pageRowsCo {
    font-size: small !important;
    width: 10% !important;
    height: 2.4rem !important;
    margin: 0 !important;
}

.paginationCo {
    display: flex;
    margin-left: 120px !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: black;
}

.statusCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 25px !important;
  }
  
  .green {
    background-color: green;
  }
  
  .red {
    background-color: red;
  }
  
  .userDetails {
    width: 100%;
    display: flex;
    justify-content: center;
}

.row.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

table.usersTbl {
  width: 100%;
}

.rows-per-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
}